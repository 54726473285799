export default {
  levels: [
    { text: '개발자', value: 0 },
    { text: '관리자', value: 1 },
    { text: '게시판관리자', value: 2 },
    { text: '게시판운영자', value: 3 },
    { text: '사용자', value: 4 },
    { text: '손님', value: 5 }
  ],
  test: 'abd333'
}
